<template>
	<div class="main">
		<h1>Classes</h1>
		
		<div class="filters catalog-filters">
			<div class="controls">
				<div v-if="!classCalView" @click="switchView('calendar')" class="class-view-toggle calendar-view-button">Calendar View &nbsp;<i class="fa fa-calendar"></i></div>
				<!-- <div v-if="!classCalView" @click="switchView('calendar')" class="class-view-toggle calendar-view-button">{{ getIsMobile ? 'Classes By Date' : 'Calendar View' }} &nbsp;<i class="fa fa-calendar"></i></div> -->
				<div v-if="classCalView" @click="switchView('list')" class="class-view-toggle list-view-button">List View &nbsp;<i class="fa fa-list"></i></div>
				<p v-if="pagination.total_items > 0">Showing {{ showingRange }} of {{ pagination.total_items }}</p>
				<button v-if="pagination.total_pages > 1" :class="['button', filters.page > 1 ? 'action' : 'cancel']" @click="loadPage('prev')"><i class="fas fa-chevron-left"></i><span style="display:none;">Prev.</span></button>
				<label v-if="pagination.total_pages > 1" for="pagination-top" style="display:none;">Page Number</label>
				<input v-if="pagination.total_pages > 1" type="text" id="pagination-top" v-model="filters.page">
				<button v-if="pagination.total_pages > 1" :class="['button', filters.page < pagination.total_pages ? 'action' : 'cancel']" @click="loadPage('next')"><i class="fas fa-chevron-right"></i><span style="display:none;">Next</span></button>
			</div>
		</div>

		<div v-if="!classCalView" class="section product-grid" style="margin-bottom: 0;">
			<div v-for="(product, index) in list" class="product-container" :product="product">
				<router-link :to="'/classesdetail/' + product.link_name + querySource"><img :src="product.image_src" class="thumbnail-image"/></router-link>
				<div class="product-title">
					{{ product.description }}
				</div>
				<div class="columns columns-2 product-meta-price">
					<div class="product-price class-length">{{ product.session_count || 1 }} Session{{ product.session_count && product.session_count > 1 ? 's' : '' }}</div>
					<div class="product-price" v-html="$formatPrice(product.customer_price)"></div>
				</div>
				<div class="controls">
					<router-link :to="'/classesdetail/' + product.link_name + querySource"><button class="button neutral full-width-button">Details</button></router-link>
				</div>
			</div>
		</div>

		<div v-if="classCalView" class="class-calendar-view">
			<div>
				<h3>Class Calendar</h3>
				<calendar-view 
					:show-date="showDate"
					:items="calendarItems"
					currentPeriodLabel="•"
					itemContentHeight="2.5em"
					:periodChangedCallback="changeMonth"
					:showTimes=false
					@click-item="showCalendarItemDetail"
					class="theme-default holiday-us-traditional ">
					<template #header="{ headerProps }">
						<calendar-view-header
							:header-props="headerProps"
							@input="setShowDate" />
					</template>
				</calendar-view>
			</div>
		</div>

		<div class="filters catalog-filters">
			<div class="controls">
				<p v-if="pagination.total_items > 0">Showing {{ showingRange }} of {{ pagination.total_items }}</p>
				<button v-if="pagination.total_pages > 1" :class="['button', filters.page > 1 ? 'action' : 'cancel']" @click="loadPage('prev')"><i class="fas fa-chevron-left"></i><span style="display:none;">Prev.</span></button>
				<label for="pagination-bottom" style="display:none;">Page Number</label><input v-if="pagination.total_pages > 1" type="text" id="pagination-bottom" v-model="filters.page">
				<button v-if="pagination.total_pages > 1" :class="['button', filters.page < pagination.total_pages ? 'action' : 'cancel']" @click="loadPage('next')"><i class="fas fa-chevron-right"></i><span style="display:none;">Next</span></button>
			</div>
		</div>

		<div class="empty-list" v-if="!loading && products.length == 0" >{{ message }}</div>
	
	</div>
</template>

<script>
// We use version 5! npm i vue-simple-calendar@5.0.1
import 'vue-simple-calendar/static/css/default.css'
import CalendarView from "vue-simple-calendar/src/components/CalendarView.vue"
import CalendarViewHeader from "vue-simple-calendar/src/components/CalendarViewHeader.vue"
export default {
	name: 'classes',
	props: ['link_name', 'product'],
	components: {
		'calendar-view': CalendarView,
		'calendar-view-header': CalendarViewHeader
	},
	data() {
		return {
			message: '',
			loading: true,
			list: [],
			pagination: {
				current_page: 1,
				total_pages: 1,
				products_per_page: 1,
				total_items: 0
			},
			classCalView: false,
			showDate: new Date(),
			calendarItems: [],
			querySource: '',
			classesByDate: {},
			showMoreMonths: 1
		}
	},
	created() {
		if (this.$route.query.view) {
			this.classCalView = this.$route.query.view == 'calendar'
		} else if (this.$route.path.indexOf('/calendar') > -1) {
			this.classCalView = true
		} else if (this.$route.path.indexOf('/list') > -1) {
			this.classCalView = false
		}
		if (this.$route.query.link_from) {
			this.querySource = '?link_from=' + this.$route.query.link_from // allows us to pass on source, e.g. mc for mailchimp
		}
		this.getCalendarItems()
	},
	computed: {
	},
	methods: {
		getCalendarItems() {
			let startDate = this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 1).toString().padStart(2,'0') + '-01'
			if (!this.classCalView) {
				startDate = this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 1).toString().padStart(2,'0') + '-' + this.showDate.getDate()
			}
			let endDate = this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 2).toString().padStart(2,'0') + '-01'
			this.apiCall({ destination: 'get_list_calendar_classes', data: { start_date: startDate, end_date: endDate } }).then(obj => {
				if (obj.status == 'success') {
					this.list = obj.list
					this.populateCalendarItemsClean(this.showDate.getFullYear() + '-' + (this.showDate.getMonth() + 1).toString().padStart(2,'0') + '-01')
				} else {
					this.message = obj.message
				}
			}).catch(err => {
				console.log(err)
			})
		},
		switchView(which) {
			this.$router.push('/classes' + (which == 'calendar' ? '/calendar' : '/list'))
		},
		setShowDate(d) {
			this.showDate = d;
			this.getCalendarItems()
		},
		changeMonth(event) {
			//this.populateCalendarItems(event.periodStart)
		},
		populateCalendarItemsClean(calendarStartDate) {
			// reset calendarItems
			this.calendarItems = []
			// calendar end will be first day of next month after calendarStartDate
			let calendarEndDate = this.$moment(calendarStartDate).add(1, 'month').format('YYYY-MM-DD')
			// generate an array of simple formatted dates to iterate
			for (const [classDetailId, detail] of Object.entries(this.list)) {
				let doDates = []
				//let seatsAvailable = detail.seats_available
				let startTime = detail.dates[0].start_time
				let endTime = detail.dates[0].end_time
				if (detail.recurring) {
					let startDate = detail.dates[0].date
					// is there a repeat end date?
					if (detail.class_frequency_end_date && detail.class_frequency_end_date < calendarEndDate) {
						calendarEndDate = detail.class_frequency_end_date;
					}
					switch (detail.class_frequency_code) {
						case 'DAY':
							// iterate through each day in the calendar month
							for (let d = calendarStartDate; d <= calendarEndDate; d = this.$moment(d).add(1, 'days').format('YYYY-MM-DD')) {
								doDates.push(d)
							}
							break;
						case 'WEEK':
							let multiplier = detail.class_frequency_unit || 1
							// find the first date in the range based on the start date and multiplier
							while (startDate < calendarStartDate) {
								startDate = this.$moment(startDate).add(7 * multiplier, 'days').format('YYYY-MM-DD')
							}
							doDates.push(startDate)
							let endDate = detail.dates[0].date
							// find the last date in the range based on the multiplier
							while (endDate <= calendarEndDate) {
								if (endDate > calendarStartDate && doDates.indexOf(endDate) == -1) {
									doDates.push(endDate)
								}
								endDate = this.$moment(endDate).add(7 * multiplier, 'days').format('YYYY-MM-DD')
							}
							break;
						case 'MONTH':
							// don't populate calendar if it's before class start year + month
							if (startDate > calendarEndDate) {
								break
							}
							if (detail.class_frequency_month_as_date == 1) {
								let monthDate = this.$moment(calendarStartDate).format('YYYY-MM') + '-' + this.$moment(startDate).format('D')
								doDates.push(monthDate)
							} else {
								let dayOfWeek = this.$moment(startDate).format('d')
								let startDayOfMonth = this.$moment(startDate).format('D')
								let ordinal = Math.ceil(startDayOfMonth / 7)
								let ordinalIndex = 1
								// iterate through each day in the calendar month
								for (let d = calendarStartDate; d <= calendarEndDate; d = this.$moment(d).add(1, 'days').format('YYYY-MM-DD')) {
									if (this.$moment(d).format('d') == dayOfWeek) { // it's the right day of the week!
										if (ordinalIndex == ordinal) { // it's the right nth day of month!
											doDates.push(d)
											break
										}
										ordinalIndex++
									}
								}
							}
							break;
					}
				} else {
					// iterate detail.dates array
					for (let i = 0; i < detail.dates.length; i++) {
						if (detail.dates[i].date >= calendarStartDate && detail.dates[i].date <= calendarEndDate) {
							doDates.push(detail.dates[i].date)
						}
					}
				}
				doDates.forEach(date => {
					let seatsAvailable = detail.seats_available
					let obj = detail.reservations.find(o => o.date == date);
					if (obj != undefined) {
						seatsAvailable -= obj.reservations
					}
					let description = detail.description
					if (classDetailId.indexOf('-S-') > -1) {
						description += "<br><i>additional session</i>"
					} else {
						description += "<br><i>" + seatsAvailable + " seat" + (seatsAvailable > 1 ? "s" : "") + " available</i>"
					}
					this.calendarItems.push({ id: detail.class_detail_id + '_' + date, startDate: date + ' ' + startTime, endDate: date + ' ' + endTime, title: description, primary_date: detail.dates[0].primary_date, seats_available: seatsAvailable })
				})
			}
		},
		showMoreDates(which) {
			let calendarEnd = new Date(this.showDate.getFullYear(), this.showDate.getMonth() + (which == 'next' ? 1 : -1), 1)
			this.setShowDate(calendarEnd)
		},
		showCalendarItemDetail(event) {
			console.log(event)
			// event.id will be class_detail_id plus the calendar index
			let detailParts = event.id.split('_')
			let classDetailId = detailParts[0]
			let classDate = event.startDate.getFullYear() + '-' + (event.startDate.getMonth()+1).toString().padStart(2,'0') + '-' + event.startDate.getDate().toString().padStart(2,'0')
			if (event.title.indexOf('additional session') > -1 && event.originalItem.primary_date) {
				classDate = event.originalItem.primary_date
			}
			this.$router.push('/classesdetail/id_' + classDetailId + '?date=' + classDate + '&from=calendar')
		},
		goClassDetail(detail) {
			//console.log(detail)
			this.$router.push('/classesdetail/id_' + detail.class_detail_id + '?date=' + detail.date + '&from=calendar')
		}
	}
}
</script>

<style lang="scss">
.class-view-toggle {
	cursor: pointer;
}
.product-meta-price {
	margin-top: 2em;
	.class-length {
		justify-self: left;
		font-size: 1.5em;
	}
}
.full-width-button {
	width: 100%;
}
.class-calendar-view {
	// NOTE: we can style elements inside a component IF scss is NOT scoped
	.theme-default .cv-header .periodLabel {
		display: block;
		text-align: right;
		font-size: 1.75em;
		color: #000;
	}
	.cv-header-nav {
		display: flex;
		button {
			color: #6f6f6f;
		}
	}
	.cv-header-days {
		background-color: #f0f0f0;
		color: #494949;
	}
	.cv-week {
		min-height: 8em;
		color: #000;
	}
	.cv-day {
		height: 8em;
		background-color: #fff;
	}
	.cv-item {
		font-size: 0.8em;
		width: 13.5% !important;
		margin: 0.25em;
		padding: 0.25em;
		text-align: center;
		cursor: pointer;
		background-color: green;
		border-color: white;
		color: white;
	}
	.theme-default .cv-item.past {
		background-color: #B1B1B1;
		border-color: #B1B1B1;
		color: #B1B1B1;
		pointer-events: none;
	}
}
</style>